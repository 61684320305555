import React, { useEffect, useState, useRef } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import baseUrl from '../../../baseUrl';
import ContactPageForm from './contact_page_form';
import Contactform from './contact_form';

const Contact = () => {
    const [jsonData, setJsonData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);
                const response = await fetch(`${baseUrl}/wp-json/wp/v2/pages/?slug=contact-us`);
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        Aos.init({
            duration: 1000, // Animation duration in milliseconds
            once: true, // Set to true if you want the animation to occur only once
        });

        fetchData();
    }, []);

    if (!jsonData) {
        return <div className="avt_loader_wrap">
            <span className="avt_loader">
                <span className="avt_loader_inner"></span>
            </span>
        </div>;


    }

    const { acf } = jsonData[0];

    return (
        <div>

            {/* ************************ */}

            <section className='avt-contact-banner-v2-sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                            <h6>
                                {acf.heading}
                            </h6>
                        </div>
                        <div className="col-lg-10">
                            <h1>
                                {acf.right_text}
                            </h1>
                            <div className='contact-list-wrap'>
                                <ul>
                                    <li>
                                        <a href={`tel:${acf.contact_details.phone}`}>
                                            <span>
                                                Phone
                                            </span>
                                            <img src="assets/images/contact/v2/phone-icon.png" alt="" />
                                            {acf.contact_details.phone}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`mailto:${acf.contact_details.email}`}>
                                            <span>
                                                Email
                                            </span>
                                            <img src="assets/images/contact/v2/email-icon.png" alt="" />
                                            {acf.contact_details.email}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='avt-contact-banner-v2-form-sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-10">
                            <div className='avt-form-wrap-v2'>
                                <Contactform />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ************************ */}



        </div>
    );
};

export default Contact;
