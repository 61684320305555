import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hovered, setHovered] = useState(false);
  const [delayedPosition, setDelayedPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    setTimeout(() => {
      setDelayedPosition({ x: e.clientX, y: e.clientY });
    }, 50);
  };

  const handleHover = () => {
    setHovered(true);
  };

  const handleUnhover = () => {
    setHovered(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  CustomCursor.setHovered = setHovered;

  return (
    <motion.div
      className={`custom-cursor ${hovered ? 'hovered' : ''}`}
      style={{ left: `${delayedPosition.x}px`, top: `${delayedPosition.y}px` }}
      initial={{ opacity: 0, scale: 0.1 }}
      animate={{ opacity: 1, scale: hovered ? 4.5 : 1 }}
      exit={{ opacity: 0, scale: 0.1 }}
      transition={{ type: 'spring', stiffness: 260, damping: 20 }}
    />
  );
};

export default CustomCursor;
