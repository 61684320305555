import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import baseUrl from '../../../baseUrl';

const TermCond = () => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        const response = await fetch(`${baseUrl}/wp-json/wp/v2/pages/?slug=terms-and-conditions`);
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    Aos.init({
      duration: 1000,
      once: true,
    });

    fetchData();
  }, []);

  if (!jsonData) {
    return (
      <div className="avt_loader_wrap">
        <span className="avt_loader">
          <span className="avt_loader_inner"></span>
        </span>
      </div>
    );
  }

  const { title, content } = jsonData[0];

  return (
    <div>
      {/* term banner sec */}
      <section className="avt-term-banner-sec">
        <div className="container">
          <div className="row avt-term-banner-row">
            <div className="col-lg-2">
              <h2>{title.rendered}</h2>
            </div>
            <div className="col-lg-10">
              {/* <p dangerouslySetInnerHTML={{ __html: content.rendered }} /> */}
            </div>
          </div>
        </div>
      </section>
      {/* term banner sec end */}

      {/* term banner content sec */}
      <section className="avt-term-content-sec">
        <div className="container">
          <div className="row avt-term-content-row">
            <div className="col-lg-2">
            </div>
            <div className="col-lg-10">
              <div className='avt-content-wrap'>
                <p dangerouslySetInnerHTML={{ __html: content.rendered }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* term banner content sec end */}

    </div>
  );
};

export default TermCond;
