import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import baseUrl from '../../../baseUrl';

const CaseStudyDetail = () => {
    const { slug } = useParams();
    // console.log('Current Slug:', slug);
    const [caseStudy, setCaseStudy] = useState({});
    const [loading, setLoading] = useState(true);
    const [allCaseStudies, setAllCaseStudies] = useState([]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const fetchAllCaseStudies = async () => {
            try {
                const response = await fetch(`${baseUrl}/wp-json/wp/v2/case-study`);
                const caseStudiesData = await response.json();

                setAllCaseStudies(caseStudiesData);
            } catch (error) {
                console.error('Error fetching all case studies:', error);
            }
        };

        Aos.init({
            duration: 1000, // Animation duration in milliseconds
            once: true, // Set to true if you want the animation to occur only once
        });

        fetchAllCaseStudies();
    }, []);

    useEffect(() => {
        const fetchCaseStudy = async () => {
            try {
                const response = await fetch(`${baseUrl}/wp-json/wp/v2/case-study?slug=${slug}`);
                const caseStudyData = await response.json();

                if (caseStudyData.length > 0) {
                    setCaseStudy(caseStudyData[0]);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching case study:', error);
                setLoading(false);
            }
        };

        fetchCaseStudy();
    }, [slug]);

    if (loading) {
        return (
            <div className="avt_loader_wrap">
                <span className="avt_loader">
                    <span className="avt_loader_inner"></span>
                </span>
            </div>
        );
    }

    // Find the index of the current case study
    const currentIndex = allCaseStudies.findIndex(item => item.id === caseStudy.id);

    // Get the indices for the previous and next case studies
    // Get the indices for the previous and next case studies
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : allCaseStudies.length - 1;
    const nextIndex = currentIndex < allCaseStudies.length - 1 ? currentIndex + 1 : 0;


    return (
        <div>
            {/* layout 1 */}
            {caseStudy.acf.select_layout === 'Layout 1' && (
                <React.Fragment>
                    {/* Case study banner */}
                    <section className="avt-about-banner avt-services-banner avt-case-main-banner-sec-v1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2">
                                    <h6>
                                        {caseStudy.acf.banner_section_1.sub_heading}
                                    </h6>
                                </div>
                                <div className="col-lg-10">
                                    <div className="avt-content-wrap">
                                        <h1>
                                            {caseStudy.title.rendered}
                                        </h1>
                                    </div>
                                    <div className="row avt-services-banner-list-wrap">
                                        <div className="col-lg-2">
                                            <h6>
                                                Services
                                            </h6>
                                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.banner_section_1.services_list }} />

                                        </div>
                                        {/* <div className="col-lg-6">
                                            <h6>
                                                Technologies
                                            </h6>
                                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.banner_section_1.technology_list }} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Case study banner end*/}

                    {/* Case study below banner section */}
                    <section className="avt-our-services-sec avt-case-study-below-banner-sec">
                        <div className="container avt-full-container-img">
                            <img src={caseStudy.acf.below_banner_section_1.image || "assets/images/case-study-detail/case-study-banner-v1.png"} alt="" />
                        </div>
                        <div className="container">
                            <div className="row avt-case-study-below-banner-row">
                                <div className="col-lg-6">
                                    <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_1.left_content }} />
                                </div>
                                <div className="col-lg-6">
                                    <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_1.right_content }} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Case study below banner section end */}
                </React.Fragment>
            )}
            {/* layout 1 end */}

            {/* layout 2 */}
            {caseStudy.acf.select_layout === 'Layout 2' && (
                <React.Fragment>

                    {/* case study banner */}
                    <section className="avt-case-main-banner-sec-v2">
                        <div className="avt-banner-img-wrap">
                            <img src={caseStudy.acf.banner_section_2.image || "assets/images/case-study-detail/case-study-banner-v1.png"} alt="" />

                        </div>
                    </section>
                    {/* case study banner end*/}
                    {/* case study below banner section */}
                    <section className="avt-our-services-sec avt-case-study-below-banner-sec">
                        <div className="container">
                            <div className="row avt-case-study-below-banner-row-v2">
                                <div className="col-lg-6 avt-left-col">
                                    <h6>
                                        <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_2.sub_heading }} />
                                    </h6>
                                    <h1>
                                        <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_2.main_heading }} />
                                    </h1>
                                </div>
                                <div className="col-lg-6 avt-right-col">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h4>
                                                CLIENT
                                            </h4>
                                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_2.client_list }} />
                                        </div>
                                        {/* <div className="col-lg-4">
                                            <h4>
                                                DATE
                                            </h4>
                                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_2.date }} />
                                        </div> */}
                                        <div className="col-lg-6">
                                            <h4>
                                                SERVICES
                                            </h4>
                                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_section_2.services_list }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row avt-case-study-below-banner-row">
                                <div className="col-lg-6">
                                    <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_content_section_2.left_content }} />
                                </div>
                                <div className="col-lg-6">
                                    <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.below_banner_content_section_2.right_content }} />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* case study below banner section end */}


                </React.Fragment>
            )}

            {/* layout 2 end */}



            {/* case study banner */}
            <section className="avt-case-study-banner-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <img src={caseStudy.acf.banner_image} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* case study banner end */}


            {/* Case study quote */}
            <section className="avt-case-study-quote-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div dangerouslySetInnerHTML={{ __html: caseStudy.acf.quote_section }} />
                        </div>
                    </div>
                </div>
            </section>
            {/* Case study quote end */}

            {/* case study nav */}
            <section className="avt-case-study-nav-sec">
                <div className="container">
                    <div className="row avt-case-study-nav-row">
                        <div className="col-lg-6 avt-prev-nav">
                            {allCaseStudies[prevIndex] && (
                                <Link to={`/case-study-detail/${allCaseStudies[prevIndex].slug}`} onClick={scrollToTop}>
                                    <h6>
                                        Previous
                                    </h6>
                                    <h3>
                                        {allCaseStudies[prevIndex].title.rendered}
                                    </h3>
                                </Link>
                            )}
                        </div>
                        <div className="col-lg-6 avt-next-nav">
                            {allCaseStudies[nextIndex] && (
                                <Link to={`/case-study-detail/${allCaseStudies[nextIndex].slug}`} onClick={scrollToTop}>
                                    <h6>
                                        Next
                                    </h6>
                                    <h3>
                                        {allCaseStudies[nextIndex].title.rendered}
                                    </h3>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* case study nav end */}
        </div>
    );
};

export default CaseStudyDetail;
